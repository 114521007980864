<template>
  <div>
    <NForm
      ref="formRef"
      label-placement="left"
      :label-width="120"
      require-mark-placement="left"
      :model="formValue"
      :rules="formRules"
    >
      <NFormItem label="教材标题：" path="title">
        <NInput
          maxlength="50"
          show-count
          clearable
          style="width: 500px;"
          v-model:value="formValue.title"
        />
      </NFormItem>
      <NFormItem label="教材简介：" path="introduce">
        <NInput
          placeholder="请输入备注"
          type="textarea"
          maxlength="500"
          show-count
          clearable
          style="width: 500px;"
          v-model:value="formValue.introduce"
        />
      </NFormItem>
    </NForm>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive } from 'vue';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { editTypeEnum } from '../enum/edit-type-map.js';

  const loading = ref(false);

  const props = defineProps({
    materialId: {
      type: String,
      required: true
    }
  });

  const resReqDataMap = {
    id: {
      reqKey: 'Material[id]',
      default: props.materialId
    },
    type: {
      reqKey: 'Material[type]',
      default: editTypeEnum.MATERIAL
    },
    title: {
      reqKey: 'Material[title]',
      default: '',
      rule: {
        required: true,
        message: '必填',
        trigger: 'blur'
      }
    },
    introduce: {
      reqKey: 'Material[introduce]',
      default: ''
    }
  };
   const getDefaultFormValue = () => {
    const tempFormValue = {};
    Object.keys(resReqDataMap).forEach(key => {
      tempFormValue[key] = resReqDataMap[key].default;
    });
    return tempFormValue;
  };

  const formRef = ref(null);
  const formValue = reactive(getDefaultFormValue());
  const formRules = (() => {
    const tempRules = {};
    Object.keys(resReqDataMap).forEach(key => {
      const rule = resReqDataMap[key].rule;
      rule && (tempRules[key] = rule);
    });
    return tempRules;
  })();

  const getReqData = () => {
    const tempReqData = {};
    Object.keys(resReqDataMap).forEach(key => {
      tempReqData[resReqDataMap[key].reqKey] = formValue[key];
    });
    return tempReqData;
  };

  const triggerFormValidator = () => {
    return new Promise(resolve => {
      formRef.value.validate(errors => {
        if (!errors) {
          resolve(getReqData());
        }
      });
    });
  };

  const initFormValue = resData => {
    Object.keys(resReqDataMap).forEach(key => {
      switch (key) {
        case 'title':
        case 'introduce':
          formValue[key] = resData[key] || resReqDataMap[key].default;
          break;
      }
    });
  };

  defineExpose({
    triggerFormValidator,
    initFormValue
  });
</script>